.dropdown {
    &:hover .dropdown__items,
    &:focus .dropdown__items {
        opacity: 1;
        visibility: visible;
    }

    .dropdown__title { border-radius: .25em .25em 0 0 ; }

    .dropdown__items {
        background-color: $color-primary;
        border-radius: 0 .25em .25em;
        color: $color-primary-invert;
        font-weight: $font-weight-light;
        left: 0;
        margin: 0;
        min-width: 150px;
        opacity: 0;
        overflow: hidden;
        padding: 0;
        position: absolute;
        text-align: left;
        top: 100%;
        transition: opacity .2s ease, visibility .2s ease;
        visibility: hidden;
    }

    .dropdown__link {
        color: $color-primary-invert;
        display: block;
        padding: .5em .75em;
        text-decoration: none;

        &:hover,
        &:focus {
            background-color: $color-primary-invert;
            color: $color-primary;
        }
    }
}


.homepage .navigation--primary {
    .dropdown__items {
        background-color: $color-primary-invert;
        color: $color-primary;
    }

    .dropdown__link {
        color: $color-primary;

        &:hover,
        &:focus {
            background-color: $color-primary;
            color: $color-primary-invert;
        }
    }
}