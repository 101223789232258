@font-face {
    font-family: 'fontello';
    src: url('../fonts/fontello/fontello.eot?25290219');
    src: url('../fonts/fontello/fontello.eot?25290219#iefix') format('embedded-opentype'),
         url('../fonts/fontello/fontello.woff2?25290219') format('woff2'),
         url('../fonts/fontello/fontello.woff?25290219') format('woff'),
         url('../fonts/fontello/fontello.ttf?25290219') format('truetype'),
         url('../fonts/fontello/fontello.svg?25290219#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
}


[class^="icon-"]::before,
[class*=" icon-"]::before {
    display: inline-block;
    font-family: 'fontello';
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1em;
    speak: none;
    text-align: center;
    text-decoration: inherit;
    text-transform: none;
    width: 1em;
}

.icon-twitter::before { content: '\f309'; }
.icon-facebook::before { content: '\f30c'; }
