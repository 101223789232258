// Wrap the navigation, this gives us our basic background styling
// and a container to hold the additions and navigation
.navigation-wrapper {
    background-color: $white;
    box-shadow: 0 5px 5px -2px rgba($black, .5);
    padding: ($gutter / 4);
    width: 100%;

    // .container {
        align-items: center;
        display: flex;
        flex-direction: column;

        @include breakpoint(970px) {
            flex-direction: row;
        }
    // }

    .menu-toggle {
        color: $color-primary;
        font-family: $font-family-display;
        text-transform: uppercase;

        &:hover,
        &:focus {
            background-color: $color-primary;
            color: $color-primary-invert;
        }

        @include breakpoint(m) {
            display: none;
        }
    }

    .site-logo {
        display: block;
        max-width: em(100px);
        min-width: em(100px);
    }
}

// Any extra items to be added that don't belong to the navigation.
// For example, a navigation toggle or a log
.navigation-additions {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include breakpoint(970px) {
        width: auto;
    }
}


.navigation--primary {
    font-family: $font-family-display;
    width: 100%;

    &.is-visible .navigation__items { display: flex; }

    // Initially hide the navigation items to be toggled when menu
    // toggle is actioned
    .navigation__items {
        align-items: center;
        display: none;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        padding-top: $gutter;
        width: 100%;
    }

    // Basic initial styles are taken from the [.pill] class, which is added
    // to the HTML. If this is not required add styles as an override.
    .navigation__item {
        position: relative;

        &:hover .navigation__link,
        &:focus .navigation__link {
            background-color: $color-primary;
            color: $color-primary-invert;
        }
    }

    .navigation__link {
        color: $color-primary;
        text-align: center;
        text-transform: uppercase;
        transition: background-color .2s ease,
                    color .2s ease;
    }

    @include breakpoint(1020) {
        // Show the navigation items when on desktop devices
        .navigation__items {
            display: flex;
            flex-direction: row;
            padding: 0;
        }

        .navigation__item {
            margin-left: ($gutter / 2);
        }

        // Force the login to be on the right of the container. Any other
        // items placed in the DOM after this element will also be forced
        // right. Make this the last item.
        .navigation__item--login,
        .navigation__item--demo-request { margin-left: auto; }

        .navigation__item--login {

            .login & {
                opacity: 0;
                visibility: hidden;
            }
        }
    }
}

.site-header {
    .navigation-wrapper {
        background-color: $color-primary;
    }

    .navigation--primary {
        .navigation__item .navigation__link {
            background-color: transparent;
            color: $color-primary-invert;

            &:hover,
            &:focus {
                background-color: $color-primary-invert;
                color: $color-primary;
            }
        }

        .navigation__item--demo-request .navigation__link {
            background-color: $color-accent;
            color: $white;
        }
    }

    .navigation__items {
        align-items: center;
        display: none;
        height: 100%;
        flex-direction: column;
        justify-content: space-between;
        padding-top: $gutter;
        width: 100%;
    }

    .ttpro-logo { color: $color-primary-invert; }
    .menu-toggle { display: block; }

    @include breakpoint(970px) {
        // .navigation-wrapper { background-color: transparent; }
        .site-logo { max-width: em(160px); }
    }

    @include breakpoint(970px) {
        .navigation__items {
            display: flex;
            flex-direction: row;
            padding: 0;
            padding-left: ($gutter / 2);
        }

        .menu-toggle { display: none; }
    }

    @include breakpoint(990px) {
        //.navigation__item--demo-request,
        //.navigation__item--support,
        .navigation__item--telephone { margin-left: auto; }
    }

    //Removes email on mobile and tablet, only view on desktop.
    .navigation__item--email {
        display: none;
    }
    @include breakpoint(1200px) {
        .navigation__item--email {
            display: inline-block;
        }
    }

    @include breakpoint(1550px) {
        //.navigation__item--demo-request { margin-left: 25%; }
        .navigation__item--telephone {
            display: inline-block;
            padding: .5em 0em;
        }
        .navigation__item--email {
            display: inline-block;
            padding: .5em 2em;
        }
    }
}

/* @include breakpoint(m) {
    .page-scrolled .site-header .navigation-wrapper {
        background-color: $color-primary;
        padding: ($gutter / 2);
    }
} */

.navigation__item--email {
    display: inline-block;
    vertical-align: middle;
    padding: .5em .50em;
    white-space: normal;
}

.navigation__item--telephone {
    display: inline-block;
    vertical-align: middle;
    padding: .5em .50em;
    white-space: normal;
}

.link-color {
    color: $color-gray;
}

.navigation__item--demo-request {
    padding: 0px 10px;
}

.fa-phone, .fa-envelope  {
    padding-right: 5px;
}

.no-wrap {
    white-space: nowrap;
}