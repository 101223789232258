.ttmedia-brand {

    &--logo { color: $off-white; }

    &--logo__part {
        display: block;
        line-height: 1;
        text-align: center;
    }

    &--logo__part:first-child {
        font-size: 3em;
        font-weight: 500;
    }

    &--logo--inline &--logo__part { display: inline; }
}
