$z-layers: (
    default: 1,
    behind: -1,
    sticky: 10,
    modal: 11
);

@function z($layer) {
    @if not map-has-key($z-layers, $layer) {
        @warn "No layer found for `#{$layer}` in $z-layers map. Property omitted.";
    }

    @return map-get($z-layers, $layer);
}
