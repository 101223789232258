h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-family-display;
    font-weight: $font-weight-light;
    letter-spacing: 1.3px;
    line-height: 1.3;
    text-transform: uppercase;
}

em { font-style: italic; }
strong { font-weight: $font-weight-bold; }

.u-text-small { font-size: $font-size-small; }
.u-text-large { font-size: $font-size-large; }
.u-text-italic { font-style: italic; }

.link {
    color: $marine;


    &:hover,
    &:focus,
    &:visited:hover { color: $amber; }

    &:visited { color: darken($slate, 15%); }
}

p + p { margin-top: ($gutter / 2); }
