$gutter: 4rem;
$columns: 12;
$min-width: rem(320px);
$max-width: rem(1800px);

@warn $min-width;

$breakpoints: (
    z: 0,
    xs: $min-width,
    s: 34.375em,
    m: 50em,
    l: 64em,
    xl: $max-width,
);

/**
 * Base colors
 */
$amber: #fe9349;
$rust: #d64d1f;
$marine: #5681cf;
$midnight-purple: #4c4b63;
$white: #fff;
$off-white: #cbd4d9;
$granite: #394951;
$onyx: #283338;
$slate: #bbc3c9;
$titanium: #efeeee;
$rose: #da3146;
$dry-blood: #a53939;
$leaf: #99cb78;
$black: #000;

/**
 * Brand color definitions
 */
$color-primary: $onyx;
$color-accent: $amber;
$color-primary-invert: $off-white;
$color-bad: $rose;
$color-good: $leaf;
$color-gray: #939393;

$color-background: $white;
$color-background-invert: $marine;
$color-background-shade: $titanium;

$color-text: $midnight-purple;
$color-text-invert: $off-white;
$color-text-weak: darken($slate, 20%);
$color-text-strong: $midnight-purple;

$color-title: $granite;



/**
 * Font styles
 */
$font-family-text: 'Open Sans', Calibri, 'Gill Sans', Candara, Optima, Helvetica, Arial, sans-serif;
$font-family-display: 'Lato';
$font-family-code: source-code-pro, Consolas, 'Andale Mono WT', 'Andale Mono', 'Lucida Console', 'Lucida Sans Typewriter', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Liberation Mono', 'Nimbus Mono L', Monaco, 'Courier New', Courier, monospace;

$font-weight-x-light: 100;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-bold: 600;
$font-weight-x-bold: 800;

$base-font-size: 16px;
$base-line-height: 1.5;

$font-size-x-small:   (em($base-font-size, $base-font-size) * .7);
$font-size-small:     (em($base-font-size, $base-font-size) * .85);
$font-size-regular:   (em($base-font-size, $base-font-size));
$font-size-large:     (em($base-font-size, $base-font-size) * 1.2);
$font-size-x-large:   (em($base-font-size, $base-font-size) * 1.5);
$font-size-xx-large:  (em($base-font-size, $base-font-size) * 1.75);
$font-size-xxx-large: (em($base-font-size, $base-font-size) * 2);
