.pill {
    background-color: $white;
    border: 1px solid $slate;
    border-radius: .25em;
    display: inline-block;
    font-size: .9em;
    padding: .5em .75em;
    text-transform: uppercase;

    &:hover {
        background-color: lighten($slate, 15%);
    }

    &__text { display: inline-block; }

    &--simple,
    &--simple:hover,
    &--simple:active {
        border-color: transparent;
    }

    &--large { font-size: 1em; }
    &--small { font-size: .8em; }
    &--x-small { font-size: .7em; }
}

a.pill {
    color: $marine;

    &:hover {
        color: darken($marine, 10%);
    }
}