// Use these following functions ad-hoc when you need to build a grid in
// pure CSS. This will work without classes on DOM elements.

/**
 * Define the element as a row when included
 * @return {void}
 */
@mixin row() {
    @extend %clearfix;

    margin: 0 row-margin();

    .row { margin: 0 (row-margin() / 2); }
}

/**
 * Define the element as a column when included
 * @param  {integer} $col-span How many columns wide should the element be
 * @param  {integer} $width    [Optional] Context for the calculations
 * @return {void}
 */
@mixin column($col-span, $width: $max-width) {
    margin: 0;
    padding: 0 column-padding();
    width: column($col-span, $width: $max-width);

    [class^='col-'] {
        padding: 0 (column-padding() / 2);
    }
}

/**
 * Define the element as a column when included, then center it in container
 * @param  {integer} $col-span How many columns wide should the element be
 * @param  {integer} $width    [Optional] Context for the calculations
 * @return {void}
 */
@mixin column-center($col-span, $width: $max-width) {
    @include column($col-span, $width: $max-width);
    float: none;
    margin: 0 auto;
}

@mixin column-fixed($col-span, $width: $max-width) {
    padding: 0 column-padding();
    width: column($col-span, $width: $max-width, $fixed: true);
}

/**
 * Define the element as a column when included, then center it in container
 * @param  {integer} $col-span How many columns wide should the element be
 * @param  {integer} $width    [Optional] Context for the calculations
 * @return {void}
 */
@mixin column-center-fixed($col-span, $width: $max-width) {
    @include column-fixed($col-span, $width: $max-width);
    float: none;
    margin: 0 auto;
}

@mixin build-columns($fixed: false, $suffix: '') {
    @for $col-num from 1 through $columns {
        .col-#{$col-num}#{$suffix} {
            @if $fixed == true {
                @include column-fixed($col-num);
            } @else {
                @include column($col-num);
            }

            @if $col-num == 12 {
                margin-bottom: ($gutter / 2);

                &:last-child { margin-bottom: 0; }
            }
        }
    }
}

// This will build a tonne of classes that you can then put on DOM elements
// which will change width at the defined breakpoints.
// WARNING: This will add hundreds of lines of CSS to your output file
@mixin build-grid($fixed: false) {
    [class^='col-'] {
        float: left;
        min-height: 1px;
        position: relative;
    }

    .row { @include row(); }

    // Build classes without extensions, old versions of IE don't support
    // media queries
    @include build-columns($fixed);

    // Build each version of the column for each breakpoint
    @each $point-name, $point-value in $breakpoints {
        // @include build-columns($fixed, -#{$point-name});

        @include breakpoint($point-name) {
            @include build-columns($fixed, -#{$point-name});
        }
    }
}
