// PAGE SECTIONS
// -------------
//
// Page sections are the main areas of content on the site. They are
// designated with a <section> DOM element with the class of .page-section.
// All content that is inside the <main> element, and is not inside an <aside>
// should be placed in a .page-section.
//
// - .page-section is the wrapper around the content and stretches to the
//   full width of the browser.
//   - Can contain an optional nav.navigation element.
//   - A --alternate modifier class can be applied to have an alternate
//     background color.
//   - A --highlight modifier class can be applied to apply the main brand
//     color to the background color. This switches the text to
//     brand-invert color. This also reduces the content padding by half.
//     NOTE: Text should be severly limited in --highlight.
//
// - .page-section__content is the content wrapper. This also stretches to the
//   full width of the browser.
//   - Can contain an optional, but useful .container class to limit the
//     width of the content.
//   - A --center class modifier can be applied to center all contained content
//
// - Regardless of element used, if a class of .page-section__title is
//   applied, the font-size and color is pre-defined. This is responsive
//   to browser resolution.

.page-section {
    $this: &; // Store a reference to this class

    background-color: $color-background;
    color: $color-primary;

    &--alternate { background-color: $color-background-shade; }
    &--highlight {
        background-color: $onyx;
        color: $off-white;

        &-light {
            background-color: $granite;
            color: $off-white;
        }

        .page-section__title { line-height: 1.8; }
    }

    &--attention {
        background-color: $dry-blood;
        color: $white;

        &-light {
            background-color: $rust;
            color: $white;
        }
    }

    &--quote {
        font-size: 1.4em;
        font-style: italic;
        text-align: center;

        @include breakpoint(m) {
            font-size: 1.8em;
        }
    }

    &__header,
    &__content,
    &__footer {
        padding: ($gutter / 2);

        &--no-padding { padding: 0; }

        @include breakpoint(m) {
            padding: $gutter;

            &--no-padding { padding: 0; }

            // Redefine padding for .page-section--narrow
            #{$this}--narrow & { padding: $gutter; }
        }
    }

    &__header {
        text-align: center;

        &--left {
            text-align: left;
            padding-bottom: ($gutter / 4);
        }
    }

    &__title {
        font-size: $font-size-large;

        + p { margin-top: ($gutter / 4); }

        @include breakpoint(m) {
            font-size: $font-size-xx-large;
        }
    }

    &__image {
        display: block;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        width: auto;
    }


    // Reset the padding to remove unnecessary padding between section parts
    &__header + &__content,
    &__content + &__footer { padding-top: 0; }

    .content__hidden {
        padding-top: $gutter;
        transition: height .2s ease-in-out;

        &.is-visible { height: auto !important; }
    }

    .container {
        padding: 0;

        // @include breakpoint(m) { padding: 0 $gutter; }
    }
}


// Place CUSTOM CONTEXT STYLING below
// ----------------------------------

// The demo request is the highlight section that contains a button
// linking to a form where a user can request a demonstration of the product.
// Initial styles COULD be applied using the utility classes, however
// this is limited when dealing with multiple browser resoultions.
.page-section--demo-request {
    .container {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        text-align: center;
    }

    .demo-request__title,
    .demo-request__actions {
        width: column(12);
    }

    .demo-request__title + .demo-request__actions { margin-top: ($gutter / 2); }

    @include breakpoint(s) {
        .demo-request__title,
        .demo-request__actions {
            padding: 0 ($gutter / 2);
            width: column(6);
        }

        .demo-request__title + .demo-request__actions { margin: 0; }
        .demo-request__title { text-align: right; }
        .demo-request__actions { text-align: left; }
    }
}

.page-section--browser-list {
    .browser-list {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 auto;
        text-align: center;

        &__item {
            min-width: 100px;
            padding: 0 column-padding();
            width: column(2);

            &:nth-child(n+3) { margin-top: ($gutter / 2); }
        }

        .browser-icon {
            height: 0;
            margin-bottom: .5em;
            overflow: hidden;
            padding-bottom: 100%;
            width: 100%;
        }
    }

    // At this point the icons need to have margins places above them,
    // and for each icon after at the icon width (100px) is added to the
    // breakpoint.
    @include breakpoint(em(364px)) {
        .browser-list__item {
            &:nth-child(n+3) { margin-top: 0; }
            &:nth-child(n+4) { margin-top: ($gutter / 2); }
        }
    }

    @include breakpoint(em(464px)) {
        .browser-list__item {
            &:nth-child(n+4) { margin-top: 0; }
            &:nth-child(n+5) { margin-top: ($gutter / 2); }
        }
    }

    @include breakpoint(em(564px)) {
        .browser-list__item {
            &:nth-child(n+5) { margin-top: 0; }
        }
    }

    @include breakpoint(m) {
        .browser-list { max-width: column(10); }
    }

    @include breakpoint(l) {
        .browser-list { max-width: column(8); }
    }
}
