// ARTICLE CARDS
// -------------
// Article cards are small snippets of text with an optional icon image
// that represent a larger item. They can be links to the article itself,
// or standalone.
//
// - Text should be limited to around 200 characters in length.
//
// - Icons are 60x60px on desktop devices and 100% width on mobile devices,
//   as such SVG or responsive images should be used.
//
// - Default width is 33% of parent container, to override create an
//   override class externally to the defined styles here.
//   - A --wide class modifier can be applied to .article-cards which will
//     make all children 50% width of parent container.
//   - A --wide class modifier can be applied to each .article-card to make
//     just that card wide. NOTE: Additional styles may be required to
//     account fot this
//
// - Article cards are responsive to device resolution and use flexbox
//   to display
//
// - Article cards work best at 100% container width, but will happily
//   work wherever. NOTE: Additional styles may be required to
//   account for this


// Spacing between article cards rows
$article-card-margin: (remove-unit($gutter) * .5rem);

.article-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.article-card {
    display: flex;
    flex-direction: column;
    font-size: $font-size-small;
    text-align: center;

    + .article-card {
        margin-top: $article-card-margin;
    }

    &__title {
        color: $color-title;
        font-size: $font-size-large;
        letter-spacing: 1.3px;
        line-height: 1;
        margin: 0 0 .5em;

        .page-section--highlight & { color: $white; }
    }

    @include breakpoint(s) {
        padding: 0 (column-padding() / 4);
        width: column(6);

        + .article-card { margin-top: 0; }

        &:nth-child(n+3) { margin-top: $article-card-margin; }
    }

    @include breakpoint(m) {
        font-size: $font-size-regular;
        padding: 0 (column-padding() / 2);
    }

    @include breakpoint(l) {
        text-align: left;
        width: column(4);

        &:nth-child(n+3) { margin-top: 0; }
        &:nth-child(n+4) { margin-top: $article-card-margin; }

        &__icon {
            height: 60px;
            margin-right: ($gutter / 2);
            width: 60px;

            svg {
                height: 60px;
                width: 60px;
            }
        }

        &__content-wrapper { width: 100%; }
    }
}

// Variant of article cards that are slightly wider, and only fit two
// across the width of their container.
.article-card--wide,
.article-cards--wide .article-card {
    @include breakpoint(l) {
        width: column(6);

        &:nth-child(n+3) { margin-top: $article-card-margin; }
    }
}

.article-card--narrow,
.article-cards--narrow .article-card {
    @include breakpoint(l) {
        width: column(3);

        &:nth-child(n+4) { margin-top: 0; }
        &:nth-child(n+5) { margin-top: $article-card-margin; }
    }
}


// Place CUSTOM CONTEXT STYLING below
// ----------------------------------
