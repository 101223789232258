.quote {
    $this: &;

    text-align: center;

    @include breakpoint(l) {
        text-align: left;
    }

    &--large {
        text-align: center;

        #{$this}__content {
            font-family: $font-family-display;
            font-size: $font-size-large;

            @include breakpoint(m) {
                font-size: $font-size-xx-large;
                line-height: 1.5;
            }
        }
    }

    &__content {
        font-size: $font-size-large;
        font-style: italic;

        &::before,
        &::after {
            content: '"';
            display: inline-block;
        }

        @include breakpoint(m) {
            font-size: $font-size-x-large;
        }

        p {
            display: inline;

            + p { margin-top: ($gutter / 4); }
        }
    }

    &__footer {
        margin-top: ($gutter / 2);

        cite {
            display: block;
            font-weight: $font-weight-bold;
        }
    }
}
