* { box-sizing: border-box; }

::selection { background: $amber; }

html,
body { height: 100%; }

body {
    color: $color-text;
    font-family: $font-family-text;
    font-size: 100%;
    font-weight: $font-weight-light;
    line-height: $base-line-height;

    // Attach the system breakpoints to the body, this way you
    // can access them from the JS and make sure breakpoints match.
    &::after {
        content: '#{inspect($breakpoints)}';
        display: none;
    }
}

svg,
img,
video {
    width: 100%;
}

a { text-decoration: none; }

.container {
    margin: 0 auto;
    max-width: $max-width;
    padding: 0 $gutter;
}

.wrapper { min-width: $min-width; }

@include build-grid;
