.cover-image {
    background-position: center center;
    background-size: cover;
    position: relative;
    z-index: z(default);

    @include breakpoint(m) {
        background-position: center 30%;
    }

    &::after {
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: z(behind);
    }

    &--narrow &__content {
        padding: 18% 0 3%;
    }

    &__content {
        margin: 0 $gutter;
        max-width: column(12, $max-width);
        padding: 27% ($gutter / 2) 3%;

        @include breakpoint(m) {
            max-width: column(8, $max-width);
        }

        @include breakpoint(l) {
            max-width: column(4, $max-width);
        }

        .container {
            background-color: rgba(darken($amber, 15%), .8);
            border-radius: .5em;
            color: $white;
            padding: ($gutter / 2);
        }

    }

    &__tagline,
    &__byline {
        color: $white;
        text-align: center;
    }

    &__tagline {
        font-size: $font-size-large;
        letter-spacing: 2px;

        @include breakpoint(m) {
            font-size: $font-size-xx-large;
        }

        @include breakpoint(l) {
            font-size: $font-size-xxx-large;
        }
    }

    &__byline {
        font-family: $font-family-text;
        font-size: $font-size-large;
        font-weight: $font-weight-light;
        text-transform: none;
    }

    &__tagline + &__byline {
        font-size: $font-size-regular;
        margin-top: ($gutter / 4);
    }

    // &__tagline + &__byline::before {
    //     background: $white;
    //     content: '';
    //     display: block;
    //     height: 1px;
    //     margin: ($gutter / 2) auto;
    //     width: 75%;
    // }
}

.cover-image--quote {
    @include breakpoint(0, m) {
        .cover-image__content { padding: 0; }

        .container {
            background-color: transparent;
            color: initial;
        }

        .quote__content { text-align: left; }
    }

    @include breakpoint(m) {
        .cover-image-mobile { display: none; }
    }
}

// .cover-image--primary {
//     &::after {
//         /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#71718e+0,4c4b63+100 */
//         background: $color-primary; /* Old browsers */
//         background: radial-gradient(ellipse at center,  rgba(lighten($color-primary, 25%), .85) 0%, rgba($color-primary, .9) 80%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
//         filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#71718e', endColorstr='#4c4b63',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
//     }
// }

// .cover-image--highlight {
//     &::after {
//         /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#71718e+0,4c4b63+100 */
//         background: $color-accent; /* Old browsers */
//         background: radial-gradient(ellipse at center,  rgba($color-accent, .75) 0%, rgba($color-accent, .9) 80%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
//         filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#71718e', endColorstr='#4c4b63',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
//     }
// }

// .cover-image--shade {
//     &::after {
//         /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#71718e+0,4c4b63+100 */
//         background: $granite; /* Old browsers */
//         background: radial-gradient(ellipse at center,  rgba(lighten($granite, 15%), .75) 0%, rgba($granite, .9) 80%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
//         filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#71718e', endColorstr='#4c4b63',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
//     }
// }

// .cover-image--alternate {
//     &::after {
//         /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#71718e+0,4c4b63+100 */
//         background: $marine; /* Old browsers */
//         background: radial-gradient(ellipse at center,  rgba($marine, .65) 0%, rgba(darken($marine, 10%), .9) 80%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
//         filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#71718e', endColorstr='#4c4b63',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
//     }
// }

// .cover-image--quote {
//     .cover-image__content .container {
//         background-color: rgba($midnight-purple, .75);
//         color: $white;
//         padding: $gutter;
//     }
// }



