.list {
    list-style-type: disc;
    margin-left: 1.5em;

    li + li {
        margin-top: ($gutter / 4);
    }
}

.list--separated {
    li + li {
        &::before {
            background-color: lighten($slate, 10%);
            content: '';
            display: block;
            height: 1px;
            margin-bottom: ($gutter / 2);
            margin-left: 50%;
            transform: translateX(-50%);
            width: 75%;
        }
    }
}
