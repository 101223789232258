$option-list-name: 'option-list';

.#{$option-list-name} {
    &__link,
    &__title,
    &__content { display: block; }

    &__link {
        border: 1px solid;
        border-radius: .25em;
        color: $color-text-weak;
        padding: ($gutter / 2);
        text-decoration: none;
        transition: color .2s ease;
    }

    &__title {
        font-family: $font-family-display;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
    }

    &__content { font-size: $font-size-small; }

    &__item + &__item { margin-top: ($gutter / 2); }

    &__item--active &__link,
    &__link:hover,
    &__link:focus {
        color: $color-text;
    }
}

