.u-center {
    display: block;
    margin: 0 auto;
}

.u-left { float: left; }
.u-right { float: right; }

.u-flex {
    display: flex;
    flex-wrap: wrap;
}

.u-flex--center-v { align-items: center; }
.u-flex--center-h { justify-content: center; }
.u-flex--row-reverse { flex-direction: row-reverse; }

.u-margin-top { margin-top: ($gutter / 2); }
.u-margin-right { margin-right: ($gutter / 2); }
.u-margin-bottom { margin-bottom: ($gutter / 2); }
.u-margin-left { margin-left: ($gutter / 2); }

// Only works on block level elements
.u-center-h { margin: 0 auto; }

.u-text-center { text-align: center; }
.u-text-left { text-align: left; }
.u-text-right { text-align: right; }

.u-clearfix { @extend %clearfix; }



