.navigation--footer {
    .navigation__items {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;

        // @include breakpoint(xl) {
        //     justify-content: flex-end;
        // }
    }

    .navigation__item {
        font-size: $font-size-small;

        + .navigation__item {
            margin-left: ($gutter / 4);

            @include breakpoint(l) {
                margin-left: ($gutter / 2);
            }
        }
    }

    .navigation__link {
        background-color: transparent;
        color: $off-white;

        &:hover,
        &:focus {
            background-color: $color-primary-invert;
            color: $color-primary;
        }
    }
}

// .section--footer {
//     .navigation--primary .navigation__items {
//         justify-content: flex-end
//     }

//     .navigation__link { color: $color-primary-invert; }

//     .navigation__item:hover .navigation__link,
//     .navigation__item:active .navigation__link {
//         background-color: $color-primary-invert;
//         color: $color-primary;
//     }
// }

