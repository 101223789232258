%clearfix {
    *zoom: 1;

    &:before,
    &:after {
        content: '';
        display: table;
        width: 0;
    }

    &:after {
        clear: both;
    }
}
