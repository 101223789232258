@mixin breakpoint($pointMin, $pointMax: 0, $type: min) {
    // A type of min can also use $pointMax, but if type is max it cannot

    // Accept both a string representing the brekapoint from $breakpoints,
    // but also a numeric value
    @if map-has-key($breakpoints, $pointMin) {
        $pointMin: map-get($breakpoints, $pointMin);
    }

    @if map-has-key($breakpoints, $pointMax) {
        $pointMax: map-get($breakpoints, $pointMax);
    }

    @if $pointMax > 0 {

        @if $type == min {
            @media (min-width: $pointMin) and (max-width: $pointMax) {
                @content;
            }
        }

    } @else {
        @media (min-width: $pointMin) {
            @content;
        }
    }
}
