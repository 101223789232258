.social-media-list {
    display: flex;
    justify-content: center;
    margin: 0 auto;
}

.page-section--footer .social-media-list {
    font-size: 1.5em;

    &__link {
        color: $white;
        font-size: 1.1em;
    }
}
