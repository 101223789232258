.page-section--footer {
    background-color: $black;
    color: $white;

    .ttmedia-brand__name { font-size: 1.35em; }
    .ttmedia-brand__tagline {
        color: $leaf;
        display: block;
    }

    .ttmedia-logo {
        display: block;
        margin: 0 auto ($gutter / 4);
        max-width: 130px;

        @include breakpoint(m) {
            margin: 0;
        }
    }

    .address {
        display: block;
        font-size: .7em;
    }
}
