.partners-list {
    &__item + &__item { border-top: 2px solid $slate; }
}

.partner {
    align-items: center;
    display: flex;
    flex-direction: column;

    @include breakpoint(l) {
        flex-direction: row;
    }

    &__description,
    &__details { padding: ($gutter / 4); }

    &__image {
        max-width: 200px;

        @include breakpoint(l) {
            padding: ($gutter / 4);
        }
    }

    &__description { flex: 1; }

    &__details {
        text-align: center;

        @include breakpoint(l) {
            flex: 0 0 300px;
        }
    }

    img { width: 100%; }
}
