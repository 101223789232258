$button-colors: (
    bright: (
        background: $marine,
        color: $white
    ),

    default: (
        background: $color-primary,
        color: $white
    ),

    highlight: (
        background: $color-accent,
        color: $white
    ),

    weak: (
        background: $slate,
        color: $color-text
    )
);

.button {
    background-color: transparent;
    border: none;
    border-radius: .15em;
    color: inherit;
    display: inline-block;
    // Normalise the buttons
    font-family: $font-family-display;
    font-size: $font-size-regular;
    font-weight: $font-weight-light;
    line-height: 1;
    padding: .75em;
    text-decoration: none;
    transition: background-color .2s ease;

    @each $key, $value in $button-colors {
        $colors: map-get($button-colors, $key);

        &--#{$key} {
            background-color: map-get($colors, background);
            color: map-get($colors, color);

            &:hover {
                background-color: darken(map-get($colors, background), 10%);
            }
        }
    }

    &--outline-dark,
    &--outline-light {
        border: em(2px) solid;
        padding: (.75em - em(2px));
    }

    &--outline-dark {
        color: $color-primary;

        &:hover,
        &:focus {
            background-color: $color-primary;
            border-color: $color-primary;
            color: $color-primary-invert;
        }
    }

    &--outline-light {
        color: $color-primary-invert;

        &:hover,
        &:focus {
            background-color: $color-primary-invert;
            border-color: $color-primary-invert;
            color: $color-primary;
        }
    }

    &--large { font-size: $font-size-large; }

    &--block {
        display: block;
        max-width: em(250px);
        text-align: center;
    }

    &--wide {
        max-width: em(400px);
    }
}
